import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { MdLockReset } from "react-icons/md";
import { useAdminUpdateUser } from "../../hooks/useAdminUpdateUser";
import { useDeleteUser } from "../../hooks/useDeleteUser";
import { User } from "../../hooks/useListUsers";
import { formatRoleName, getRoleBadgeColor } from "../../utils/formatters";
import { EditUserModal } from "./EditUserModal";


interface UsersTableProps {
  users: User[];
  loading: boolean;
  error: string | null;
  onUserDeleted: (userId: string) => void;
  onUserUpdated: (user: User) => void;
}

export const UsersTable = ({ users, loading, error, onUserDeleted, onUserUpdated }: UsersTableProps) => {
  const currentUserEmail = useMemo(() => localStorage.getItem("email"), []);
  const currentUserRole = useMemo(() => {
    const user = users.find(u => u.email === currentUserEmail);
    return user?.user_organizations[0]?.role_id;
  }, [users, currentUserEmail]);

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isResetOpen, onOpen: onResetOpen, onClose: onResetClose } = useDisclosure();
  const [resetUrl, setResetUrl] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { deleteUser, loading: deleteLoading } = useDeleteUser();
  const { updateUser, loading: resetLoading } = useAdminUpdateUser();
  const toast = useToast();
  const organization_id = localStorage.getItem("organization_id") || "";

  const handleResetPassword = async (user: User) => {
    const organization_id = localStorage.getItem("organization_id");
    if (!organization_id) {
      toast({
        title: "Error",
        description: "Organization ID not found",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setSelectedUser(user);
    const result = await updateUser({
      email: user.email,
      full_name: user.full_name,
      organization_id,
      phone_number: user.phone_number || "",
      role_id: user.user_organizations[0]?.role_id || "USER",
      reset_password: true,
    });

    if (result?.reset_url) {
      setResetUrl(result.reset_url);
      onResetOpen();
    }
  };

  const handleDelete = async (user: User) => {
    setSelectedUser(user);
    onDeleteOpen();
  };

  const handleEdit = (user: User) => {
    setUserToEdit(user);
    onEditOpen();
  };

  const confirmDelete = async () => {
    if (!selectedUser) return;

    const success = await deleteUser({
      email: selectedUser.email,
      organization_id,
    });

    if (success) {
      onUserDeleted(selectedUser.id);
    }
    onDeleteClose();
  };

  if (loading) {
    return (
      <Center height="70vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text ml={4}>Loading users...</Text>
      </Center>
    );
  }

  if (error || !users || users.length === 0) {
    return (
      <Center height="70vh">
         <Text ml={4}>Loading users...</Text>
      </Center>
    );
  }

  return (
    <Box>
      <Table>
        <Thead position="sticky" top={0} bg="white" zIndex={1}>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th>Phone</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td>
                <HStack>
                  <Text color="gray.600">{user.full_name}</Text>
                  {user.email === currentUserEmail && (
                    <Badge colorScheme="gray" fontSize="xs">
                      You
                    </Badge>
                  )}
                </HStack>
              </Td>
              <Td>
                <Text color="gray.600">{user.email}</Text>
              </Td>
              <Td>
                <Badge 
                  colorScheme={getRoleBadgeColor(user.user_organizations[0]?.role_id)}
                  minWidth="100px"
                  textAlign="center"
                  px={2}
                >
                  {formatRoleName(user.user_organizations[0]?.role_id)}
                </Badge>
              </Td>
              <Td>
                <Text color="gray.600">{user.phone_number || "-"}</Text>
              </Td>
              <Td>
                <HStack spacing="1">
                  <Tooltip label="Reset Password">
                    <IconButton
                      icon={<MdLockReset />}
                      variant="ghost"
                      colorScheme="orange"
                      aria-label="Reset password"
                      onClick={() => handleResetPassword(user)}
                      isLoading={resetLoading && selectedUser?.id === user.id}
                      isDisabled={user.user_organizations[0]?.role_id === "SUPER_ADMIN" || (user.user_organizations[0]?.role_id === "ORG_ADMIN" && currentUserRole !== "SUPER_ADMIN")}
                      mr={1}
                    />
                  </Tooltip>
                  <Tooltip label="Edit user">
                    <IconButton
                      icon={<FiEdit2 />}
                      variant="ghost"
                      colorScheme="blue"
                      aria-label="Edit user"
                      onClick={() => handleEdit(user)}
                      isDisabled={user.user_organizations[0]?.role_id === "SUPER_ADMIN" || (user.user_organizations[0]?.role_id === "ORG_ADMIN" && currentUserRole !== "SUPER_ADMIN")}
                    />
                  </Tooltip>
                  <Tooltip label="Delete user">
                    <IconButton
                      icon={<FiTrash2 />}
                      variant="ghost"
                      colorScheme="red"
                      aria-label="Delete user"
                      onClick={() => handleDelete(user)}
                      isDisabled={user.user_organizations[0]?.role_id === "SUPER_ADMIN" || (user.user_organizations[0]?.role_id === "ORG_ADMIN" && currentUserRole !== "SUPER_ADMIN")}
                    />
                  </Tooltip>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete {selectedUser?.full_name}? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose} isDisabled={deleteLoading}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmDelete}
                ml={3}
                isLoading={deleteLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {userToEdit && (
        <EditUserModal
          isOpen={isEditOpen}
          onClose={onEditClose}
          onSuccess={(updatedUser) => {
            onUserUpdated(updatedUser);
            setUserToEdit(null);
          }}
          user={userToEdit}
        />
      )}
      {/* Reset Password Modal */}
      <Modal isOpen={isResetOpen} onClose={onResetClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth="1px" pb={4}>Reset Password Link</ModalHeader>
          <ModalBody pt={6}>
            <Text fontSize="md" fontWeight="medium" color="gray.700" mb={4}>
              To reset the password for <Text as="span" color="blue.600">{selectedUser?.email}</Text> please share or use this link:
            </Text>
            <Box 
              bg="gray.50" 
              p={4} 
              borderRadius="md" 
              borderWidth="1px"
              borderColor="gray.200"
            >
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  value={resetUrl}
                  isReadOnly
                  bg="white"
                  fontFamily="mono"
                />
                <InputRightElement width="4.5rem">
                  <Button 
                    h="1.75rem" 
                    size="sm"
                    onClick={() => {
                      navigator.clipboard.writeText(resetUrl);
                      toast({
                        title: "Copied to clipboard",
                        status: "success",
                        duration: 2000,
                      });
                    }}
                  >
                    Copy
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
            <Text fontSize="sm" color="gray.600" mt={4}>
              Please use this link or share it with the user:
            </Text>
          </ModalBody>
          <ModalFooter borderTopWidth="1px" pt={4}>
            <Button colorScheme="blue" onClick={onResetClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
