import { useToast } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export interface User {
  id: string;
  disabled: boolean;
  email: string;
  full_name: string;
  phone_number: string | null;
  user_organizations: {
    user_id: string;
    organization_id: string;
    id: string;
    role_id: string;
    organization: {
      name: string;
      id: string;
    };
  }[];
}

export const useListUsers = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const toast = useToast();

  const organization_id = localStorage.getItem("organization_id");

  const listUsers = useCallback(async (forceRefresh: boolean = false) => {
    if (users.length > 0 && !forceRefresh) {
      return users;
    }

    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token) {
        throw new Error("Session expired");
      }

      const url = new URL(`${API_URL}/api/v1/user/`);
      if (organization_id) {
        url.searchParams.append('organization_id', organization_id);
      }

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to get users");
      }

      const data = await response.json();
      setUsers(data);
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to get users",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [setUsers, setLoading, setError, toast, organization_id]);

  useMemo(() => {
    if (users.length === 0) {
      listUsers();
    }
  }, [users.length, listUsers]);

  return { listUsers, error, loading, users };
};
