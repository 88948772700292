import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useState, useMemo, useEffect } from "react";
import { useCreateUser } from "../../hooks/useCreateUser";
import { useOrganizations } from "../../hooks/useOrganizations";
import { useRoleAuth } from "../../hooks/useRoleAuth";
import { User } from "../../hooks/useListUsers";
import { formatRoleName } from "../../utils/formatters";

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (user: User) => void;
}

export const AddUserModal = ({ isOpen, onClose, onSuccess }: AddUserModalProps) => {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [roleId, setRoleId] = useState<"USER" | "ADMIN" | "ORG_ADMIN">("USER");
  const [selectedOrgId, setSelectedOrgId] = useState("");

  const { createUser, loading } = useCreateUser();
  const { organizations } = useOrganizations();
  const { role: currentUserRole } = useRoleAuth();
  const toast = useToast();
  const organization_id = currentUserRole === "SUPER_ADMIN" ? selectedOrgId : localStorage.getItem("organization_id");

  // Set initial organization for non-SUPER_ADMIN users
  useEffect(() => {
    if (currentUserRole !== "SUPER_ADMIN" && organization_id) {
      setSelectedOrgId(organization_id);
    }
  }, [currentUserRole, organization_id]);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = useMemo(() => {
    const hasValidOrg = currentUserRole === "SUPER_ADMIN" ? selectedOrgId !== "" : true;
    return email && fullName && password && isValidEmail(email) && hasValidOrg;
  }, [email, fullName, password, selectedOrgId, currentUserRole]);

  const handleSubmit = async () => {
    if (!isValidEmail(email)) {
      toast({
        title: "Invalid email",
        description: "Please enter a valid email address",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (!organization_id) {
      toast({
        title: "Error",
        description: "Organization ID not found",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const currentOrgId = localStorage.getItem("organization_id");
    const result = await createUser({
      email,
      full_name: fullName,
      password,
      organization_id,
      phone_number: phoneNumber,
      role_id: roleId,
    });

    if (result) {
      // Only update the table if the user was created in the current organization
      if (organization_id === currentOrgId) {
        onSuccess(result);
      } else {
        toast({
          title: "User created",
          description: "User has been created in a different organization",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
      // Reset form
      setEmail("");
      setFullName("");
      setPassword("");
      setPhoneNumber("");
      setRoleId("USER");
      setSelectedOrgId("");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
              <FormControl isRequired isInvalid={email !== "" && !isValidEmail(email)}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {email !== "" && !isValidEmail(email) && (
                  <FormErrorMessage>Please enter a valid email address</FormErrorMessage>
                )}
              </FormControl>
            <FormControl isRequired>
              <FormLabel>Full Name</FormLabel>
              <Input
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
            {currentUserRole === "SUPER_ADMIN" && (
              <FormControl isRequired>
                <FormLabel>Organization</FormLabel>
                <Select
                  value={selectedOrgId}
                  onChange={(e) => setSelectedOrgId(e.target.value)}
                  placeholder="Select organization"
                >
                  {organizations.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl isRequired>
              <FormLabel>Role</FormLabel>
              <Select
                value={roleId}
                onChange={(e) => setRoleId(e.target.value as "USER" | "ADMIN" | "ORG_ADMIN")}
              >
                <option value="USER">{formatRoleName("USER")}</option>
                <option value="ADMIN">{formatRoleName("ADMIN")}</option>
                {currentUserRole === "SUPER_ADMIN" && (
                  <option value="ORG_ADMIN">{formatRoleName("ORG_ADMIN")}</option>
                )}
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="purple"
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={!isFormValid}
          >
            Add User
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
