import { useState } from 'react';
import { User } from './useListUsers';
import { loadToken } from '../utils/validateToken';
import { API_URL } from '../utils/constants';

interface AdminUpdateUserRequest {
  email: string;
  organization_id: string;
  full_name: string;
  phone_number?: string;
  role_id: string;
  reset_password?: boolean;
}

interface AdminUpdateUserResponse {
  user: User;
  reset_url?: string;
}

export const useAdminUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateUser = async (request: AdminUpdateUserRequest): Promise<AdminUpdateUserResponse | null> => {
    setLoading(true);
    setError(null);

    try {
      const token = loadToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(
        `${API_URL}/api/v1/user/${encodeURIComponent(request.email)}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'accept': 'application/json',
          },
          body: JSON.stringify({
            user_update_request: {
              organization_id: request.organization_id,
              full_name: request.full_name,
              phone_number: request.phone_number,
              role_id: request.role_id,
              reset_password: request.reset_password || false,
            },
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update user');
      }

      const data = await response.json();
      return data;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred while updating the user';
      setError(errorMessage);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { updateUser, loading, error };
};
