import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { FiMoreVertical, FiUser, FiLogOut } from "react-icons/fi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { CheckIcon } from "@chakra-ui/icons";
import { useOrganizations } from "../../hooks/useOrganizations";
import { useNavigate } from "react-router-dom";
import { APP_VERSION } from "../../utils/constants";
import { useRoleAuth } from "../../hooks/useRoleAuth";
export const QuickMenu = () => {
  const navigate = useNavigate();
  const { organizations, currentOrganization, switchOrganization } = useOrganizations();
  const { hasPermission } = useRoleAuth();
  const handleNavClick = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    // Remove the access_token from localStorage
    localStorage.clear();
    // Redirect to the login page or home page
    handleNavClick("/login");
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<FiMoreVertical />}
        variant="ghost"
        _hover={{ bg: 'white.100' }}
        _active={{ bg: 'gray.100' }}
      />
      <MenuList>
        <MenuGroup title="Profile">
          <MenuItem onClick={() => handleNavClick("/settings")}>
            <HStack spacing={2}>
              <Icon as={FiUser} boxSize={3.5} color="gray.500" />
              <Text fontSize="sm">My Account</Text>
            </HStack>
          </MenuItem>
          <MenuItem onClick={() => handleLogout()}>
            <HStack spacing={2}>
              <Icon as={FiLogOut} boxSize={3.5} color="gray.500" />
              <Text fontSize="sm">Log out</Text>
            </HStack>
          </MenuItem>

          <MenuDivider />

          {organizations.length > 1 && (
            <>
              <MenuDivider />
              <MenuGroup title="Organizations">
                {organizations.map((org) => (
                  <MenuItem
                    key={org.id}
                    onClick={() => switchOrganization(org.id)}
                    closeOnSelect={true}
                  >
                    <HStack justify="space-between" width="100%" spacing={3}>
                      <HStack spacing={2}>
                        <Icon as={HiOutlineBuildingOffice2} boxSize={3.5} color="gray.500" />
                        <Text fontSize="sm">{org.name}</Text>
                      </HStack>
                      {org.id === currentOrganization?.id && (
                        <Icon as={CheckIcon} color="blue.500" boxSize={4} />
                      )}
                    </HStack>
                  </MenuItem>
                ))}
              </MenuGroup>
            </>
          )}

          <MenuDivider />
          <Box px={4} py={3}>
            <Text fontSize="xs" color="gray.500">
              App Version:{" "}
              {parseInt(APP_VERSION[0]) < 1
                ? `${APP_VERSION} (Beta)`
                : APP_VERSION}
            </Text>
          </Box>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
