import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AgentVersion, Instruction } from "../../client/types";
import { useCreateAgentVersion } from "../../hooks/useCreateAgentVersion";
import { useGetAgent } from "../../hooks/useGetAgent";
import { useGetAgentVersion } from "../../hooks/useGetAgentVersion";
import { useGetEngine } from "../../hooks/useGetEngine";
import { useListAgentsVersions } from "../../hooks/useListAgentsVersions";

export const NewVersion = ({
  agentId,
  onNewAgentVersion,
}: {
  agentId: string;
  onNewAgentVersion: (agent: AgentVersion) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { createAgentVersion, loading } = useCreateAgentVersion();

  // Fetch the agent versions and engine data
  const { agentVersions } = useListAgentsVersions(agentId);
  const { agent } = useGetAgent(agentId);
  const { engine } = useGetEngine(agent?.engine?.id || "");
  const engine_instructions = engine?.instructions;

  // State to manage form inputs and selected version
  const [versionName, setVersionName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedVersionId, setSelectedVersionId] = useState<string | null>(
    null
  );
  const [selectedVersionData, setSelectedVersionData] = useState<any>(null);

  const { getAgentVersion, loading: versionLoading } = useGetAgentVersion(
    agentId,
    selectedVersionId === "engine-prompt" ? "" : selectedVersionId || ""
  );
  const latestVersion = agentVersions.find((v) => v.is_default) || {};
  // Fetch the selected version's data when selectedVersionId changes
  useEffect(() => {
    const fetchVersionData = async () => {
      try {
        if (!selectedVersionId) {
          // If no version selected, use default version
          const defaultVersion = agentVersions.find(v => v.is_default);
          if (defaultVersion) {
            setSelectedVersionData(defaultVersion);
          }
        } else if (selectedVersionId === "engine-prompt") {
          // If engine prompt selected, use engine instructions
          console.log("Selected engine prompt, instructions:", engine_instructions);

          if (!engine_instructions) {
            throw new Error("No engine instructions available");
          }

          // Engine instructions are already normalized in useGetEngine
          if (typeof engine_instructions === 'object' && 'role' in engine_instructions) {
            const instructions = engine_instructions as Instruction;
            if (!instructions.role && !instructions.general_context && !instructions.ask) {
              throw new Error("Invalid engine instructions format - no content");
            }
            setSelectedVersionData({ instructions });
          } else {
            throw new Error("Invalid engine instructions format");
          }
        } else {
          // If specific version selected, fetch its data
          const versionData = await getAgentVersion();
          if (versionData) {
            setSelectedVersionData(versionData);
          }
        }
      } catch (error) {
        console.error("Error fetching version data:", error);
        setSelectedVersionData(null);
      }
    };
    fetchVersionData();
  }, [selectedVersionId, getAgentVersion, engine_instructions, agentVersions]);

  const handleSave = async () => {
    try {
      if (!selectedVersionData?.instructions) {
        throw new Error("No valid instructions found for the selected source");
      }

      // Ensure instructions are in the correct format
      const instructions = selectedVersionData.instructions;
      if (!(typeof instructions === 'object' && 'role' in instructions)) {
        throw new Error("Invalid instructions format");
      }

      // Create version data with proper instruction format
      const versionData = {
        agent_id: agentId,
        name: versionName,
        description: description,
        is_default: false,
        instructions: {
          role: instructions.role || instructions.ROLE || "",
          general_context: instructions.general_context || instructions.GENERAL_CONTEXT || "",
          ask: instructions.ask || instructions.ASK || "",
          examples: (instructions.examples || instructions.EXAMPLES || []).map((ex: any) => ({
            input: ex.input || ex.INPUT || "",
            output: ex.output || ex.OUTPUT || ""
          }))
        }
      };

      console.log("Creating version with data:", versionData);

      const newAgentVersion = await createAgentVersion(agentId, versionData);

      // Pass the new agent version to the parent component
      if (newAgentVersion) {
        onNewAgentVersion(newAgentVersion);
        // Close the modal
        onClose();
      }
    } catch (error: any) {
      console.error("Error creating version:", error);
      toast({
        title: "Error creating version",
        description: error.message || "Failed to create version. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button onClick={onOpen}>New version</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new version</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Version name</FormLabel>
              <Input
                ref={initialRef}
                value={versionName}
                placeholder={`${latestVersion.name || "Version"} ${agentVersions.length + 1
                  }`}
                onChange={(e) => setVersionName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Input
                value={description}
                placeholder="This is a new version"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Based on</FormLabel>
              <Select
                placeholder="Select version"
                value={selectedVersionId || ""}
                onChange={(e) => setSelectedVersionId(e.target.value)}
              >
                {agentVersions.map((agentVersion) => (
                  <option key={agentVersion.id} value={agentVersion.id}>
                    {agentVersion.is_default
                      ? agentVersion.name + " (Default)"
                      : agentVersion.name}
                  </option>
                ))}
                {engine && (
                  <option value="engine-prompt">
                    Use engine prompt ({engine.id})
                  </option>
                )}
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              colorScheme="brand"
              variant="outline"
              isDisabled={loading}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleSave}
              isDisabled={
                loading || versionLoading || !versionName || !description || !selectedVersionData || !selectedVersionData?.instructions
              }
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
