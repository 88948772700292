import {
  Box,
  Button,
  Checkbox,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Text,
  Spinner,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useListAgentsFiles } from "../../hooks/useListAgentsFiles";
import { useAttachAgentFile } from "../../hooks/useAttachAgentFile";
import { useDetachAgentFile } from "../../hooks/useDetachAgentFile";
import { useListAgentVersionFiles } from "../../hooks/useListAgentVersionFiles";
import { File } from "../../client/types";

interface AgentFilesTableProps {
  agentId: string;
  versionId: string;
  toolPurpose: string;
}

export const AgentFilesTable = ({
  agentId,
  versionId,
  toolPurpose,
}: AgentFilesTableProps) => {
  const {
    files: allFiles,
    loading: filesLoading,
    error: filesError,
  } = useListAgentsFiles(agentId);
  const {
    files: attachedFiles,
    loading: versionFilesLoading,
    error: versionFilesError,
    listVersionFiles,
  } = useListAgentVersionFiles(versionId, toolPurpose);
  const { attachFile } = useAttachAgentFile();
  const { detachFile } = useDetachAgentFile();
  const [processingFiles, setProcessingFiles] = useState<string[]>([]);
  const toast = useToast();

  const handleCheckboxChange = async (fileId: string, isAttached: boolean) => {
    setProcessingFiles((prev) => [...prev, fileId]);
    try {
      if (isAttached) {
        await detachFile({ versionId, fileId, toolPurpose });
        toast({
          title: "File detached successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await attachFile({
          versionId,
          fileId,
          toolPurpose,
        });
        toast({
          title: "File attached successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      await listVersionFiles();
    } catch (error) {
      console.error("Error handling file:", error);
      toast({
        title: "Error processing file",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setProcessingFiles((prev) => prev.filter((id) => id !== fileId));
    }
  };

  if (filesLoading || versionFilesLoading) {
    return (
      <Box textAlign="center" py={4}>
        <Spinner size="xl" />
        <Text mt={2}>Loading files...</Text>
      </Box>
    );
  }

  if (filesError || versionFilesError) {
    return (
      <Box textAlign="center" py={4}>
        <Text color="red.500">Error loading files. Please try again.</Text>
      </Box>
    );
  }

  const isFileAttached = (fileId: string) => {
    return attachedFiles.some((af) => af.file_id === fileId);
  };

  if (allFiles.length === 0) {
    return (
      <Box textAlign="center" py={4}>
        <Text mb={5}>
          To attach files to this Agent Version, please upload them first to the
          Agent
        </Text>
        <Button
          as={RouterLink}
          to={`/agents/${agentId}?setup=true`}
          colorScheme="brand"
          size="sm"
        >
          Go to Agent Setup
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th width="50px"></Th>
            <Th>Name</Th>
            <Th>Description</Th>
            <Th>Uploaded</Th>
          </Tr>
        </Thead>
        <Tbody>
          {allFiles.map((file: File) => (
            <Tr key={file.id}>
              <Td>
                <Tooltip
                  label={
                    processingFiles.includes(file.id)
                      ? isFileAttached(file.id)
                        ? "Detaching file..."
                        : "Attaching file..."
                      : isFileAttached(file.id)
                        ? "Click to detach"
                        : "Click to attach"
                  }
                >
                  <HStack spacing={2}>
                    <Checkbox
                      isChecked={isFileAttached(file.id)}
                      onChange={() =>
                        handleCheckboxChange(file.id, isFileAttached(file.id))
                      }
                      isDisabled={processingFiles.includes(file.id)}
                    />
                    {processingFiles.includes(file.id) && (
                      <Spinner size="sm" color="purple.500" />
                    )}
                  </HStack>
                </Tooltip>
              </Td>
              <Td>{file.name}</Td>
              <Td>{file.description}</Td>
              <Td>{new Date(file.uploaded_at).toLocaleDateString()}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
