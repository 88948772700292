import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AgentsList } from "../../client/types";
import { useListAgents } from "../../hooks/useListAgents";
import { MyAgentsTable } from "./MyAgentsTable";
import { NewAgent } from "./NewAgent";
import { useRoleAuth } from "../../hooks/useRoleAuth";

export const MyAgentsHome = () => {
  const { hasPermission } = useRoleAuth();

  // State to manage the list of agents
  const [agents, setAgents] = useState<AgentsList[]>([]);

  // Fetch the list of agents
  const { agents: fetchedAgents, error } = useListAgents();

  // Use useEffect to update the agents list when fetchedAgents change
  useEffect(() => {
    if (fetchedAgents && fetchedAgents.length > 0) {
      setAgents(fetchedAgents);
    } else {
      setAgents([]);
    }
  }, [fetchedAgents]); // This will only run when fetchedAgents changes

  // Function to handle the addition of a new agent to the list
  const handleNewAgent = (newAgent: AgentsList) => {
    setAgents((prevAgents) => [...prevAgents, newAgent]);
    if (error) {
      // Clear the error message if there was one
      window.location.reload();
    }
  };

  return (
    <Flex direction="column" width="100%">
      <Flex
        justify="space-between"
        align="center"
        py={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          My Agents
        </Text>
        {hasPermission(['ADMIN', 'SUPER_ADMIN']) && (
          <NewAgent onNewAgent={handleNewAgent} />
        )}
      </Flex>

      <Flex flex="1">
        <Box flex="1">
          {fetchedAgents.length === 0 && error ? (
            <Text textAlign="center" mt={8}>
              {error}
            </Text>
          ) : (
            <MyAgentsTable agents={agents} />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
