import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";
import { createAgentVersion } from "../client/types";
import { loadToken } from "../utils/validateToken";


export const useCreateAgentVersion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const token = loadToken() || "";

  const createAgentVersion = async (agentId: string, agentData: createAgentVersion) => {
    setLoading(true);
    setError(null);

    try {
      console.log("Creating agent version with data:", agentData);
      
      // Parse instructions back to object if it's a string
      const processedData = {
        ...agentData,
        instructions: typeof agentData.instructions === 'string' 
          ? JSON.parse(agentData.instructions)
          : agentData.instructions
      };
      
      console.log("Processed data:", processedData);

      const response = await fetch(`${API_URL}/api/v1/agents/${agentId}/versions`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(processedData),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to create agent version");
      }

      toast({
        title: "Agent version created.",
        description: "The agent version has been successfully created.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      window.location.reload();
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Agent version creation failed.",
        description: "Failed to create agent version. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { createAgentVersion, error, loading };
};
