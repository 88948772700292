import { Box, Flex } from "@chakra-ui/react";
import { AgentsDashboard } from "../components/MyAgents/AgentsDashboard";
import { SideBar } from "../components/Sidebar/SideBar";
import { useListAgents } from "../hooks/useListAgents";
export const Home = () => {
  const { agents, loading } = useListAgents();

  return (
    <Flex direction="row" minH="100vh">
      <SideBar agents={agents} agentsLoading={loading}/>
      <Box flex="1" display="flex" px="6">
        <AgentsDashboard />
      </Box>
    </Flex>
  );
};
