import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Engine } from "../client/types";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useGetEngine = (engineId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const token = loadToken() || "";

  const [engine, setEngine] = useState<Engine | null>(null);

  const getEngine = useCallback(async () => {
    if (!engineId) {
      setError("Engine ID is required");
      return;
    }
    if (engine) {
      return engine;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/api/v1/engines/${engineId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch engine");
      }

      const data = await response.json();
      console.log("Raw engine data:", data);
      
      // Parse and normalize instructions if they exist
      if (data.instructions) {
        try {
          // Parse the instructions string
          const parsedInstructions = typeof data.instructions === 'string' 
            ? JSON.parse(data.instructions)
            : data.instructions;

          // Convert to proper format
          data.instructions = {
            role: parsedInstructions.ROLE || parsedInstructions.role || "",
            general_context: parsedInstructions.GENERAL_CONTEXT || parsedInstructions.general_context || "",
            ask: parsedInstructions.ASK || parsedInstructions.ask || "",
            examples: (parsedInstructions.EXAMPLES || parsedInstructions.examples || []).map((ex: any) => ({
              input: ex.INPUT || ex.input || "",
              output: ex.OUTPUT || ex.output || ""
            }))
          };

          console.log("Normalized instructions:", data.instructions);
        } catch (error) {
          console.error("Error parsing engine instructions:", error);
          data.instructions = null;
        }
      }
      
      setEngine(data);
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to get engine",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [engineId, token, engine, setEngine, setLoading, setError, toast]);

  useEffect(() => {
    if (!engine && engineId) {
      getEngine();
    }
  }, [engine, engineId, getEngine]);

  return { getEngine, engine, loading, error };
};
