import { useState, useCallback, useEffect } from 'react';
import { API_URL } from '../utils/constants';
import { loadToken } from '../utils/validateToken';

interface UserOrganization {
  user_id: string;
  organization_id: string;
  id: string;
  role_id: string;
  organization: {
    name: string;
    id: string;
  };
}

export interface Organization {
  id: string;
  name: string;
  role_id: string;
}

export const useOrganizations = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Load organizations from user data
  const loadOrganizations = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const token = loadToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(`${API_URL}/api/v1/user/me`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
      const orgs = userData.user_organizations.map((org: UserOrganization) => ({
        id: org.organization_id,
        name: org.organization.name,
        role_id: org.role_id,
      }));

      setOrganizations(orgs);

      // Set current organization
      const storedOrgId = localStorage.getItem('organization_id');
      const currentOrg = orgs.find((org: Organization) => org.id === storedOrgId) || orgs[0];
      
      if (currentOrg) {
        setCurrentOrganization(currentOrg);
        localStorage.setItem('organization_id', currentOrg.id);
        localStorage.setItem('organization', currentOrg.name);
        localStorage.setItem('role_id', currentOrg.role_id);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  // Switch organization
  const switchOrganization = useCallback((orgId: string) => {
    const newOrg = organizations.find(org => org.id === orgId);
    if (newOrg) {
      setCurrentOrganization(newOrg);
      localStorage.setItem('organization_id', newOrg.id);
      localStorage.setItem('organization', newOrg.name);
      localStorage.setItem('role_id', newOrg.role_id);
      window.location.reload(); // Reload to refresh data with new organization context
    }
  }, [organizations]);

  useEffect(() => {
    loadOrganizations();
  }, [loadOrganizations]);

  return {
    organizations,
    currentOrganization,
    switchOrganization,
    loading,
    error,
  };
};
