import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Logo } from "../components/Common/Logo";
import { PasswordField } from "../components/Login/PasswordField";
import { useResetPassword } from "../hooks/useResetPassword";

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const { resetPassword, loading, error } = useResetPassword();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast();

  const handleReset = async () => {
    if (!token) {
      toast({
        title: "Invalid reset link",
        description: "Please request a new password reset link.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (password !== confirmPassword) {
      toast({
        title: "Passwords do not match",
        description: "Please make sure both passwords match.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (password.length < 8) {
      toast({
        title: "Password too short",
        description: "Password must be at least 8 characters long.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const success = await resetPassword({
      token,
      new_password: password,
    });

    if (success) {
      toast({
        title: "Password reset successful",
        description: "You can now log in with your new password.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    }
  };

  if (!token) {
    return (
      <Container maxW="lg" py={{ base: "12", md: "24" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing="8">
          <Stack spacing="6" textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>Invalid Reset Link</Heading>
            <Text color="fg.muted">
              Please request a new password reset link.
            </Text>
          </Stack>
        </Stack>
      </Container>
    );
  }

  return (
    <Container position="relative">
      <Center>
        <Logo />
      </Center>

      <Container maxW="lg" py={{ base: "0", sm: "8" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>Reset your password</Heading>
              <Text color="fg.muted">
                Please enter your new password
              </Text>
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <PasswordField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="New password"
                />
                <PasswordField
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                />
              </Stack>
              <Stack spacing="6">
                <Button
                  onClick={handleReset}
                  isLoading={loading}
                  isDisabled={!password || !confirmPassword || loading}
                >
                  Reset Password
                </Button>
                {error && <Text color="red.500">{error}</Text>}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Container>
  );
};
