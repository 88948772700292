import { useState } from 'react';
import { API_URL } from '../utils/constants';

interface ResetPasswordRequest {
  token: string;
  new_password: string;
}

export const useResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const resetPassword = async (request: ResetPasswordRequest): Promise<boolean> => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${API_URL}/api/v1/user/reset-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
          },
          body: JSON.stringify({
            token: request.token,
            new_password: request.new_password,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to reset password');
      }

      return true;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred while resetting the password';
      setError(errorMessage);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { resetPassword, loading, error };
};
