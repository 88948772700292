import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAdminUpdateUser } from "../../hooks/useAdminUpdateUser";
import { User, useListUsers } from "../../hooks/useListUsers";
import { useRoleAuth } from "../../hooks/useRoleAuth";
import { formatRoleName } from "../../utils/formatters";

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (user: User) => void;
  user: User;
}

export const EditUserModal = ({ isOpen, onClose, onSuccess, user }: EditUserModalProps) => {
  const [fullName, setFullName] = useState(user.full_name);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number || "");
  const [roleId, setRoleId] = useState(user.user_organizations[0]?.role_id || "USER");

  const { updateUser, loading, error: updateError } = useAdminUpdateUser();
  const { listUsers } = useListUsers();
  const { role: currentUserRole } = useRoleAuth();
  const toast = useToast();
  const organization_id = localStorage.getItem("organization_id");

  // Update form when user prop changes
  useEffect(() => {
    setFullName(user.full_name);
    setPhoneNumber(user.phone_number || "");
    setRoleId(user.user_organizations[0]?.role_id || "USER");
  }, [user]);

  const handleSubmit = async () => {
    if (!organization_id) {
      toast({
        title: "Error",
        description: "Organization ID not found",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const result = await updateUser({
      email: user.email,
      full_name: fullName,
      organization_id,
      phone_number: phoneNumber,
      role_id: roleId,
      reset_password: false,
    });

      if (result) {
        // Construct the updated user object with the existing user_organizations
        const updatedUser: User = {
          ...result.user,
          user_organizations: [
            {
              ...user.user_organizations[0],
              role_id: roleId
            }
          ]
        };
        
        onSuccess(updatedUser);
        onClose();
        toast({
          title: "Success",
          description: "User updated successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error("Failed to update user");
      }
    } catch (err) {
      toast({
        title: "Error",
        description: updateError || "Failed to update user",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Full Name</FormLabel>
              <Input
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Role</FormLabel>
              <Select
                value={roleId}
                onChange={(e) => setRoleId(e.target.value)}
                isDisabled={user.user_organizations[0]?.role_id === "SUPER_ADMIN"}
              >
                <option value="USER">{formatRoleName("USER")}</option>
                <option value="ADMIN">{formatRoleName("ADMIN")}</option>
                {currentUserRole === "SUPER_ADMIN" && (
                  <option value="ORG_ADMIN">{formatRoleName("ORG_ADMIN")}</option>
                )}
              </Select>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="purple"
            onClick={handleSubmit}
            isLoading={loading}
            isDisabled={!fullName || loading}
          >
            Update User
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
