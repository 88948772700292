import { Box, Flex } from "@chakra-ui/react";
import { AdminSettings } from "../components/Admin/AdminSettings";
import { SideBar } from "../components/Sidebar/SideBar";
import { useListAgents } from "../hooks/useListAgents";

export const Admin = () => {
  const { agents, loading } = useListAgents();

  return (
    <Flex direction="row" h="100vh">
      <SideBar agents={agents} agentsLoading={loading}/>
      <Box flex="1" overflow="hidden">
        <Box px="6" h="100%" overflowY="auto">
          <AdminSettings />
        </Box>
      </Box>
    </Flex>
  );
};
