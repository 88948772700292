import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";
import { User } from "./useListUsers";

interface CreateUserParams {
  email: string;
  full_name: string;
  password: string;
  organization_id: string;
  phone_number: string;
  role_id: "USER" | "ADMIN" | "ORG_ADMIN"
}

export const useCreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const createUser = useCallback(async (params: CreateUserParams) => {
    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token) {
        throw new Error("Session expired");
      }

      const response = await fetch(
        `${API_URL}/api/v1/user/`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Failed to create user");
      }

      // Since the API returns empty object on success, create user object from params
      const newUser: User = {
        id: crypto.randomUUID(), // Temporary ID until list refresh
        email: params.email,
        full_name: params.full_name,
        phone_number: params.phone_number,
        disabled: false,
        user_organizations: [{
          user_id: crypto.randomUUID(),
          organization_id: params.organization_id,
          id: crypto.randomUUID(),
          role_id: params.role_id,
          organization: {
            name: localStorage.getItem("organization") || "",
            id: params.organization_id,
          }
        }]
      };

      toast({
        title: "User created",
        description: "New user has been added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      
      return newUser;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to create user",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return null;
    } finally {
      setLoading(false);
    }
  }, [toast]);

  return { createUser, loading, error };
};
