import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Agent as AgentType } from "../client/types";
import { AgentHome } from "../components/Agent/AgentHome";
import { SideBar } from "../components/Sidebar/SideBar";
import { useListAgents } from "../hooks/useListAgents";

export const Agent = () => {
  const { id, assistant_id } = useParams<{
    id: string;
    assistant_id: string;
  }>();
  const { agents, loading, listAgents } = useListAgents();
  const [selectedAgent, setSelectedAgent] = useState<AgentType | null>(null);

  // Set initial selected agent based on URL param
  useEffect(() => {
    if (agents && id) {
      const agent = agents.find(a => a.id === id);
      if (agent) {
        setSelectedAgent(agent);
      }
    }
  }, [agents, id]);

  const handleAgentSelect = (agent: AgentType) => {
    setSelectedAgent(agent);
  };

  const handleAgentUpdate = async (updatedAgent: AgentType) => {
    setSelectedAgent(updatedAgent);
    // Update the agent in the agents list
    if (agents) {
      const updatedAgents = agents.map(agent => 
        agent.id === updatedAgent.id ? updatedAgent : agent
      );
      // Update localStorage to match the API state
      localStorage.setItem("agents", JSON.stringify(updatedAgents));
      // Force refresh the agents list to update sidebar
      await listAgents(true);
    }
  };

  return (
    <Flex direction="row" minH="100vh">
      <SideBar 
        agent_version={assistant_id ?? ""} 
        agents={agents ? agents.map(agent => ({...agent})) : []} 
        agentsLoading={loading}
        onAgentSelect={handleAgentSelect}
      />
      <Box flex="1" display="flex" px="6">
        <AgentHome 
          selectedAgent={selectedAgent} 
          onAgentUpdate={handleAgentUpdate}
        />
      </Box>
    </Flex>
  );
};
