import {
  Box,
  Button,
  Collapse,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { FiSettings, FiChevronDown } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarToggleButton } from "../Sidebar/SidebarToggleButton";
import { useRoleAuth } from "../../hooks/useRoleAuth";

export const AdminCollapse = ({
  isCollapsed,
}: {
  isCollapsed?: boolean;
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { isSuperAdmin, isAdmin } = useRoleAuth();
  const navigate = useNavigate();
  const location = useLocation();

  if (!isSuperAdmin() && !isAdmin()) {
    return null;
  }

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggle();
  };

  if (isCollapsed) {
    return (
      <SidebarToggleButton
        leftIcon={<FiSettings />}
        onClick={handleToggle}
        isCollapsed={isCollapsed}
      >
        Administration
      </SidebarToggleButton>
    );
  }

  return (
    <Box>
      <Button
        variant="tertiary"
        width="full"
        justifyContent="space-between"
        pl="3"
        onClick={handleToggle}
      >
        <HStack spacing="3">
          <Icon as={FiSettings} />
          <Text as="span">Administration</Text>
        </HStack>
        <PopoverIcon isOpen={isOpen} />
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Stack
          spacing="1"
          alignItems="stretch"
          ps="8"
          py="1"
        >
          <Button
            variant="tertiary"
            width="full"
            justifyContent="start"
            onClick={() => navigate("/admin")}
            isActive={location.pathname === '/admin'}
          >
            User Management
          </Button>
        </Stack>
      </Collapse>
    </Box>
  );
};

export const PopoverIcon = (props: { isOpen: boolean }) => {
  const iconStyles = {
    transform: props.isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
};
