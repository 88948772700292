import { SettingsIcon } from "@chakra-ui/icons";
import { Box, Center,Spinner, Flex, IconButton, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { Agent } from "../../client/types";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { AgentVersions } from "../AgentVersions/AgentVersions";
import { KnowledgeBaseTable } from "../KnowledgeBase/KnowledgeBaseTable";
import { AgentSetup } from "./AgentSetup";

interface AgentHomeProps {
  selectedAgent: Agent | null;
  onAgentUpdate: (agent: Agent) => void;
}

export const AgentHome = ({ selectedAgent, onAgentUpdate }: AgentHomeProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { flags } = useFeatureFlags();
  const [showAgentKnowledgeBase, setShowAgentKnowledgeBase] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const showSetup = queryParams.get("setup") === "true";

  const handleNameUpdate = (newName: string) => {
    if (selectedAgent) {
      const updatedAgent = { ...selectedAgent, name: newName };
      onAgentUpdate(updatedAgent);
    }
  };

  const toggleSetup = () => {
    const newShowSetup = !showSetup;
    queryParams.set("setup", newShowSetup.toString());
    navigate({ search: queryParams.toString() });
  };

  if (!selectedAgent) {
    return <Center height="100vh" width="100%">
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
    <Text ml={4}>Loading your agent.</Text>
  </Center>
  }

  return (
    <Flex
      direction="column"
      minH="80vh"
      width="100%"
      maxH="100vh"
      overflowY="auto"
    >
      <Flex
        justify="space-between"
        align="center"
        py={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          {selectedAgent.name ?? "Agent"}
        </Text>

        <Flex align="center">
          {flags?.showAgentKnowledgeBase ? (
            <Box position="relative" mr={2}>
              <IconButton
                aria-label="Upload"
                icon={<FiUpload />}
                size="md"
                variant="ghost"
                onClick={
                  setShowAgentKnowledgeBase
                    ? () => setShowAgentKnowledgeBase((prev) => !prev)
                    : undefined
                }
                isDisabled={showSetup}
              />

              <Text
                position="absolute"
                top="24px"
                right="-8px"
                fontSize="xx-small"
                color="purple.500"
                fontWeight="bold"
                hidden={showSetup}
              >
                Beta
              </Text>
            </Box>
          ) : null}

          {!showSetup && (
            <IconButton
              aria-label="Toggle Setup"
              icon={<SettingsIcon />}
              onClick={toggleSetup}
              size="md"
              variant="ghost"
              isDisabled={showAgentKnowledgeBase}
            />
          )}
        </Flex>
      </Flex>

      <Flex direction="column" py={4}>
        {showSetup ? (
          <AgentSetup Agent={selectedAgent} onChange={handleNameUpdate} />
        ) : showAgentKnowledgeBase ? (
          <KnowledgeBaseTable agent_id={selectedAgent.id} />
        ) : (
          <AgentVersions id={selectedAgent.id} agentName={
            selectedAgent.name
          } />
        )}
      </Flex>
    </Flex>
  );
};
