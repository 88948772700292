import { Box, Button, Flex, Heading, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { UsersTable } from "./UsersTable";
import { useRoleAuth } from "../../hooks/useRoleAuth";
import { AddUserModal } from "./AddUserModal";
import { useListUsers } from "../../hooks/useListUsers";
import { AddIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { User } from "../../hooks/useListUsers";

export const AdminSettings = () => {
  const { isSuperAdmin, isOrgAdmin } = useRoleAuth();
  const organization = localStorage.getItem("organization");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { users: initialUsers, loading, error, listUsers } = useListUsers();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const loadUsers = async () => {
      const refreshedUsers = await listUsers(true);
      if (refreshedUsers) {
        setUsers(refreshedUsers);
      }
    };
    loadUsers();
  }, [listUsers]);

  const handleUserCreated = async (newUser: User) => {
    // Add the user immediately with temporary ID
    setUsers(prevUsers => [...prevUsers, newUser]);
    
    // No need to refresh from API - we already have the user data
  };

  const handleUserUpdated = (updatedUser: User) => {
    // Update the specific user in the list immediately
    setUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === updatedUser.id ? updatedUser : user
      )
    );
    
    // No need to refresh from API - we already have the updated user data
  };



  return (
    <Flex direction="column" width="100%" h="100%">
      <Flex
        justify="space-between"
        align="center"
        py={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          {organization} Users
        </Text>
        {(isSuperAdmin() || isOrgAdmin()) && (
          <Button
            leftIcon={<AddIcon />}
            colorScheme="brand"
            variant="solid"
            size="sm"
            onClick={onOpen}
          >
            Add User
          </Button>
        )}
      </Flex>

      <Flex flex="1">
        <Box flex="1">
          {users.length === 0 && error ? (
            <Text textAlign="center" mt={8}>
              {error}
            </Text>
          ) : (
            <UsersTable
              users={users}
              loading={loading}
              error={error}
              onUserDeleted={(userId) => {
                setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
              }}
              onUserUpdated={handleUserUpdated}
            />
          )}
        </Box>
      </Flex>
      <AddUserModal
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={handleUserCreated}
      />
    </Flex>
  );
};
