import { Box, Button, ButtonProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface SidebarToggleButtonProps extends ButtonProps {
  leftIcon: React.ReactElement;
  isCollapsed: boolean;
  children: ReactNode;
}

export const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({
  leftIcon,
  onClick,
  isCollapsed,
  children,
}) => {
  return (
    <Button
      leftIcon={leftIcon}
      onClick={onClick}
      variant="tertiary"
      justifyContent={isCollapsed ? "center" : "flex-start"}
      width="100%"
      transition="all 0.2s"
      iconSpacing={isCollapsed ? "0" : "3"}
    >
      <Box
        opacity={isCollapsed ? 0 : 1}
        transition="opacity 0.2s"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {children}
      </Box>
    </Button>
  );
};
