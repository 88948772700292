/**
 * Formats a role ID by removing underscores and properly capitalizing each word
 * @param role The role ID to format (e.g., "ORG_ADMIN")
 * @returns Formatted role name (e.g., "Org Admin")
 */
export const formatRoleName = (role: string): string => {
  // Replace underscores with spaces and capitalize each word
  return role
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
/**
 * Returns the color for the role badge based on the role
 * @param role The role ID to get the color for
 * @returns colorScheme for the role badge
 */
export const getRoleBadgeColor = (role: string) => {
  switch (role) {
    case "SUPER_ADMIN":
      return "green";
    case "ORG_ADMIN":
      return "blue";
    case "ADMIN":
      return "yellow";
    default:
      return "gray";
  }
};
