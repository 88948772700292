import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import { IoAnalytics } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useRoleAuth } from "../../hooks/useRoleAuth";
import { Logo } from "../Common/Logo";
import { QuickMenu } from "./QuickMenu";
import { SidebarButton } from "./SidebarButton";
import { AgentsCollapse } from "../MyAgents/AgentsCollapse";
import { AdminCollapse } from "../Admin/AdminCollapse";
import { Agent } from "../../client/types";

export const SideBar = ({ 
  agents, 
  agent_version, 
  agentsLoading,
  onAgentSelect 
}: { 
  agents: Agent[]; 
  agent_version?: string;
  agentsLoading?: boolean;
  onAgentSelect?: (agent: Agent) => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = localStorage.getItem("email") || "";
  const full_name = localStorage.getItem("full_name") || "";
  const { flags } = useFeatureFlags();
  const { hasPermission } = useRoleAuth();

  const [width, setWidth] = useState<number>(() => {
    const storedWidth = localStorage.getItem("sidebarWidth");
    return storedWidth ? parseInt(storedWidth) : 256; // default width
  });

  const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
    const storedIsCollapsed = localStorage.getItem("sidebarCollapsed");
    return storedIsCollapsed ? JSON.parse(storedIsCollapsed) : false;
  });

  // Determine what elements to show based on width
  const showEmail = width > 256;
  const showName = width > 200;
  const showAvatar = width > 60;

  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  useEffect(() => {
    localStorage.setItem("sidebarWidth", width.toString());
  }, [width]);

  useEffect(() => {
    // Auto-collapse when width gets too small
    if (width <= 60 && !isCollapsed) {
      setIsCollapsed(true);
    }
  }, [width]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    
    const startDrag = (e: MouseEvent) => {
      const newWidth = e.clientX;
      // Ensure width stays within reasonable bounds (60px - 600px)
      if (newWidth >= 60 && newWidth <= 600) {
        setWidth(newWidth);
      }
    };
    
    const stopDrag = () => {
      document.removeEventListener('mousemove', startDrag);
      document.removeEventListener('mouseup', stopDrag);
    };

    document.addEventListener('mousemove', startDrag);
    document.addEventListener('mouseup', stopDrag);
  }, []);

  const handleNavClick = (path: string) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Flex as="section" maxH="100vh">
      <Stack
        flex="1"
        width={isCollapsed ? "60px" : `${width}px`}
        py={{ base: "4", sm: "6", md: "8" }}
        px={isCollapsed ? "2" : { base: "2", sm: "4", md: "6" }}
        bg="bg.surface"
        borderRightWidth="1px"
        justifyContent="space-between"
        position="relative"
        transition={isCollapsed ? "width 0.2s" : "none"}
      >
        <Stack spacing="6" overflowY="auto" flex="1">
            <Box alignSelf="center" overflow="visible">
            <Logo
              minW={isCollapsed ? "6vh" : { base: "12vh", sm: "11.5vh" }}
              minH={isCollapsed ? "6vh" : { base: "12vh", sm: "11.5vh" }}
              transition="all 0.2s"
              opacity={isCollapsed ? 1 : 1}
            />
            </Box>
          <Stack spacing="1">
            {flags?.showDashboard && hasPermission(['ADMIN', 'SUPER_ADMIN']) && (
              <SidebarButton
          leftIcon={<IoAnalytics />}
          isActive={location.pathname.toLowerCase() === "/dashboard"}
          onClick={() => handleNavClick("/dashboard")}
          isCollapsed={isCollapsed}
              >
          Dashboard
              </SidebarButton>
            )}
            <AgentsCollapse 
              isCollapsed={isCollapsed} 
              agents={agents} 
              loading={agentsLoading || false}
              onAgentSelect={onAgentSelect}
            />
            <AdminCollapse isCollapsed={isCollapsed} />
          </Stack>
        </Stack>
        <Stack spacing="4" divider={<StackDivider />}>
          <Box />
          <HStack spacing="2" justify="space-between" width="100%" overflow="hidden">
            {!isCollapsed ? (
              <>
                <HStack spacing="2" flex="1" minW={0}>
                  {showAvatar && (
                    <Avatar
                      size="sm"
                      name={full_name}
                      src=""
                      bg="blue.500"
                      color="white"
                      fontSize="sm"
                      fontWeight="bold"
                      flexShrink={0}
                    />
                  )}
                  <Box
                    transition="all 0.2s"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    minW={0}
                  >
                    <VStack align="start" spacing={0.5}>
                      {showName && (
                        <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
                          {full_name}
                        </Text>
                      )}
                      {showEmail && (
                        <Text fontSize="xs" color="gray.500" noOfLines={1}>
                          {email}
                        </Text>
                      )}
                    </VStack>
                  </Box>
                </HStack>
                <Box flexShrink={0}>
                  <QuickMenu />
                </Box>
              </>
            ) : (
              <QuickMenu />
            )}
          </HStack>
        </Stack>
        <IconButton
          aria-label="Toggle Sidebar"
          icon={
            isCollapsed ? (
              <ArrowRightIcon boxSize={3} />
            ) : (
              <ArrowLeftIcon boxSize={3} />
            )
          }
          onClick={toggleSidebar}
          position="absolute"
          right={{ base: "-8px", md: "-10px" }}
          top="50%"
          transform="translateY(-50%)"
          bg="white"
          color="gray.500"
          _hover={{ bg: "gray.100" }}
          boxShadow="sm"
          size="sm"
          borderRadius="full"
        />
        {!isCollapsed && (
          <Box
            position="absolute"
            right="-2px"
            top="0"
            bottom="0"
            width="4px"
            cursor="ew-resize"
            onMouseDown={handleMouseDown}
            _hover={{ bg: "gray.200" }}
            transition="background-color 0.2s"
          />
        )}
      </Stack>
    </Flex>
  );
};
